import PropTypes from 'prop-types'

function Button({text, onClick}) {
    return (
        <button className='f6 link dim br-pill ph3 pv2 mb2 mh2 dib white bg-dark-blue' onClick={onClick}>
            {text}
        </button>
    )
}

const onClick = (e) =>  {
    console.log(e)
}

Button.defaultProps = {
    text: 'Change Me',
    onClick: onClick
}

Button.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func
}

export default Button
