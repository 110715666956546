// download-link.js
import { useApi } from './use-api';
import { useAuth0 } from '@auth0/auth0-react';

export const FeedExport = () => {
  const opts = {
    audience: 'https://eis-api.cyentia.com',
    scope: 'read:enhanced',
  };
  const { getAccessTokenWithPopup } = useAuth0();
  const { loading, error, refresh, data: download_url} = useApi(
    'https://api.eis.cyentia.com/v1/export',
    opts
  );
  const getTokenAndTryAgain = async () => {
    await getAccessTokenWithPopup(opts);
    refresh();
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    if (error.error === 'login_required') {
      return <button onClick={() => getAccessTokenWithPopup(opts)}>Login</button>;
    }
    if (error.error === 'consent_required') {
      return (
        <button onClick={getTokenAndTryAgain}>Consent to EIS Permissions</button>
      );
    }
    return <div>Oops {error.message}</div>;
  }
  console.log("Download URL is", download_url.download_url)
  var dlLink = download_url.download_url
  return (
    <div>
      <a href={dlLink} target="_blank" rel="noreferrer">Export Current Feed</a>
    </div>
  );
};

export default FeedExport;

