import { useAuth0 } from '@auth0/auth0-react';
//import { useState } from 'react'
import Header from './components/Header';
//import Cves from './components/Cves'
import Button from './components/Button'
import FeedExport from './components/FeedExport'
//import { createBrowserHistory } from 'history'


function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();

  //const history = createBrowserHistory();

  const opts = {
    audience: 'https://eis-api.cyentia.com',
    scope: 'read:enhanced',
  };

  /*
  const onRedirectCallback = (appState) => {
    history.replaceState(appState?.returnTo || window.location.pathname);
  }
  */

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    return (
      <div className="App">
        <section className="mw5 mw7-ns center pa3 ph5-ns">
          <Header />
          <div className="tc">
            <p className="lh-copy pa2 mv4 tc bg-black-20">Hello {user.name} ({user.nickname}) - Subscriber ID: {user.sub}</p>
            <FeedExport />
            <Button onClick={() => logout({ returnTo: window.location.origin })} text = "Log Out" />
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className="App">
        <section className="mw5 mw7-ns center pa3 ph5-ns">
          <Header />
          <div className="tc">
          <Button text="Sign Up" onClick={() => loginWithRedirect({ opts, screen_hint: 'signup' })} />
          <Button text="Log In" onClick={() => loginWithRedirect(opts)} />
          <p>Looking to learn more about EIS? Visit our <a href="https://cyentia.com/eis">product page</a> to learn more!</p>
          </div>
        </section>
      </div>
    );
  }
}

export default App;
