import logo from '../logo.png'

function Header() {
  return (
    <header className='tc pv4 pv5-ns'>
      <img src={logo} className='pa1 ba b--black-10 h3' alt='Cyentia logo' />
      <h1 className='f5 f4-ns fw6 mid-gray'>Exploit Intelligence Service</h1>
      <h2 className='f6 f4-ns fw4 mid-gray'>Customer Portal</h2>
      <h2 className='f6 gray fw2 ttu tracked'>by Cyentia</h2>
    </header>
  )
}

export default Header